<template>
  <div id="logins">
    <img class="head_img" src="@img/login/login.png" alt="" />
    <div class="table">
      <!-- 标题 -->
      <p class="head_name">{{language.l_113}}</p>
      <!-- 表单 -->
      <van-form @submit="onSubmit" ref="form">
         <!-- 邮箱 -->
        <p class="mailbox">{{language.l_101}}</p>
        <van-field v-model="params.email" :placeholder="language.l_102" :rules="formVerify.email" name="email" left-icon="invition" autocomplete="off" clearable class="email"/>
        <!-- 手机号码 -->
        <p class="mailbox">{{language.l_115}}</p>
        <van-field v-model="params.tel" :placeholder="language.l_116" name="tel" :rules="formVerify.tel" autocomplete="off" left-icon="phone" clearable class="email"/>
        <!-- 密码 -->
        <p class="mailbox">{{language.l_92}}</p>
        <van-field @click-right-icon="psdFlag = !psdFlag" v-model="params.pwd" :placeholder="language.l_93" name="pwd" :rules="formVerify.pwd" :type="psdFlag ? 'password' : ''" autocomplete="off" left-icon="lock" clearable class="email">
          <template #right-icon><van-icon :name="psdFlag ? 'closed-eye' : 'eye'" color="#FB2C37"/></template>
        </van-field>
         <!-- 重新输入密码 -->
        <p class="mailbox">{{language.l_94}}</p>
        <van-field @click-right-icon="rePwdFlag = !rePwdFlag" v-model="params.rePwd" name="rePwd" :rules="formVerify.rePwd" :placeholder="language.l_91" :type="rePwdFlag ? 'password' : ''" autocomplete="off" left-icon="lock" clearable class="email">
          <template #right-icon><van-icon :name="rePwdFlag ? 'closed-eye' : 'eye'" color="#FB2C37"/></template>
        </van-field>
        <!-- 邮箱验证 -->
        <p class="mailbox">{{language.l_103}}</p>
        <van-field v-model="params.code" :placeholder="language.l_104" :rules="formVerify.code" autocomplete="off" name="code" left-icon="lock" clearable class="email">
          <!-- 邮箱验证码 -->
          <template #button>
            <van-button @click.prevent="codeIng" :disabled="btnFlag" size="mini">{{ btnFlag ? `${time}${language.l_105}` : language.l_106 }}</van-button>
          </template>
        </van-field>
        <!-- 注册按钮 -->
        <van-button native-type="submit" color="#FB2C37" class="signIn">{{language.l_113}}</van-button>
         <!-- 返回按钮 -->
        <div class="returnBut">
          <van-button @click="goBack">{{language.l_99_2}}</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import {register, sms_code} from "@api";

export default {
  data() {
    return {
      params: {
        email: "",
        code: "",
        tel: "",
        pwd: "",
        rePwd: "",
        type: "register",
      },
      time: "90",
      timeId: null,
      btnFlag: false,
      language: this.$store.getters.language,
      psdFlag: true,
      rePwdFlag: true
    };
  },
  methods: {
    codeIng() {//发送验证码
      this.$refs.form.validate("email").then(() => {
        const { email, type } = this.$data.params;
        this.btnFlag = true;
        sms_code({ email, type }).then(() => {
          this.$toast(this.language.l_117);
          let count = this.time;
          this.timeId = setInterval(() => {
            if (count == 1) {
              clearInterval(this.timeId);
              return (this.btnFlag = false);
            }
            count--;
            count = `${count}`.padStart(2, "0");
            this.time = count;
          }, 1000);
        })  //发送成功
      })
    },
    onSubmit(values) { //提交表单
      if (this.params.pwd == this.params.rePwd) {
        const { email, code, tel, pwd, rePwd } = this.$data.params;
        register({ email, code, tel, pwd, rePwd }) //密码修改
          .then(() => {
            this.$toast.success(this.language.l_118);  //注册成功
            this.$router.push("/login");
          })
      } else {
        this.$toast(this.language.l_119);
      }
    },
    goBack(){  //返回上一页
      this.$router.go(-1)
    }
  },
  computed: {
    formVerify() {
      const rules = {
        email: [{ required: true, message: this.language.l_107 }],  //邮箱不能为空
        code: [{ required: true, message: this.language.l_108 }],  //验证码不能为空
        tel: [{ required: true, message: this.language.l_120 }],  //手机号不能为空
        pwd: [{ required: true, message: this.language.l_99 }],  //密码不能为空
        rePwd: [{ required: true, message: this.language.l_99 }],  //密码不能为空
      };
      return rules;
    },
  },
  beforeDestroy() {
    clearInterval(this.timeId);
  },
};
</script>
<style lang='less' scoped>
#logins {
  background: #fff;
  .head_img {
    width: 100%;
    height: 244px;
  }
  .table {
    padding: 20px 25.5px;
    .head_name {
      font-size: 24px;
      font-weight: bold;
      color: #013358;
    }
    .mailbox {
      padding: 30px 0 10px 0;
      font-size: 14px;
      font-weight: bold;
      color: #013358;
    }
    .email {
      display: flex;
      align-items: center;
      background: #fef3f1;
      border-radius: 4px;
      /deep/ input::-webkit-input-placeholder {
        color: @c_deep;
      }
      /deep/ .van-field__control {
        padding-left: 8px;
      }
      .forget {
        text-align: center;
        min-width: 77px;
        color: @c_deep;
        height: 24px;
        line-height: 24px;
        padding-left: 6px;
        border-left: 2px solid @c_deep;
      }
      /deep/ .van-field__left-icon {
        i {
          color: @c_deep;
        }
      }
    }
    /deep/ .van-field__button .van-button {
      min-width: 70px;
    }
    /deep/ .van-field__error-message {
      padding-left: 8px;
    }
    /deep/ .van-field__button {
      display: flex;
      align-items: center;
      height: 24px;
      border-left: 1px solid @c_deep;
      .van-button--default {
        background-color: transparent !important;
        font-size: 12px;
        color: @c_deep;
        border: none;
      }
    }
    .signIn{
      width: 100%;
      border-radius: 8px;
      margin: 40px 0 0 0;
    }
    .returnBut{
       /deep/ button {
        width: 100%;
        border-radius: 8px;
        margin: 10px 0;
        color: #fff;
        background: @c_deep;
      }
    }
  }
}
</style>
